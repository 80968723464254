const optionsNDS = [
    {
        id:   'БезНДС',
        name: 'Без НДС',
        shortName: 'Без НДС',
        rate: 1.0,
    },
    {
        id:   'НДС0',
        name: 'НДС 0%',
        shortName: '0%',
        rate: 1.0,
    },
    {
        id:   'НДС5',
        name: 'НДС 5%',
        shortName: '0%',
        rate: 1.05,
    },
    {
        id:   'НДС7',
        name: 'НДС 7%',
        shortName: '0%',
        rate: 1.07,
    },
    {
        id:   'НДС10',
        name: 'НДС 10%',
        shortName: '10%',
        rate: 1.1,
    },
    {
        id:   'НДС12',
        name: 'НДС 12%',
        shortName: '12%',
        rate: 1.12,
    },
    {
        id:   'НДС15',
        name: 'НДС 15%',
        shortName: '15%',
        rate: 1.15,
    },
    {
        id:   'НДС18',
        name: 'НДС 18%',
        shortName: '18%',
        rate: 1.18,
    },
    {
        id:   'НДС20',
        name: 'НДС 20%',
        shortName: '20%',
        rate: 1.2,
    },
];

export default optionsNDS;